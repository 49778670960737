import React, { Children, useState } from 'react';

import { ReactComponent as SVGArrow } from '@public/global/carousel/arrow.svg';

import styles from './Carousel.module.css';

export interface CarouselProps {
    isAnimated?: boolean;
    customStyles?: Record<string, string> | undefined;
    arrow?: React.ReactNode | undefined;
}

export default function Carousel({
    children,
    isAnimated = true,
    customStyles,
    arrow,
}: React.PropsWithChildren<CarouselProps>) {
    const arrayChildren = Children.toArray(children);

    const childrenCount: number = Children.count(children);
    const current = Math.ceil(childrenCount / 2);
    const style: Record<string, string> = customStyles !== undefined ? customStyles : styles;

    const tmpIndexChildren: Array<number> = [];
    for (let i = 0; i < childrenCount; i++) {
        tmpIndexChildren.push(i);
    }

    for (let i = 1; i < current; i++) {
        if (tmpIndexChildren.length > 0) {
            tmpIndexChildren.unshift(tmpIndexChildren.pop() as number);
        }
    }

    const [indexChildren, setIndexChildren] = useState(tmpIndexChildren);
    const [isAnimatingFromRight, setIsAnimatingFromRight] = useState(false);
    const [isAnimatingFromLeft, setIsAnimatingFromLeft] = useState(false);

    const goToPrevious = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (isAnimatingFromLeft || isAnimatingFromRight) return;
        const tmpChildren = [...indexChildren];
        tmpChildren.unshift(tmpChildren.pop() as number);
        setIndexChildren(tmpChildren);
        setIsAnimatingFromLeft(true);
        setTimeout(() => {
            setIsAnimatingFromLeft(false);
        }, 300); //todo: onanimationend
    };
    const goToNext = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (isAnimatingFromLeft || isAnimatingFromRight) return;
        const tmpChildren = [...indexChildren];

        tmpChildren.push(tmpChildren.shift() as number);
        setIndexChildren(tmpChildren);
        setIsAnimatingFromRight(true);
        setTimeout(() => {
            setIsAnimatingFromRight(false);
        }, 300);
    };

    return (
        <div className={style['carousel']}>
            <ul
                className={`${isAnimated === true && isAnimatingFromLeft && style['animate-from-left']} ${
                    isAnimated === true && isAnimatingFromRight && style['animate-from-right']
                }`}
            >
                {childrenCount % 2 === 0 && <li>{arrayChildren[childrenCount - 2]}</li>}
                <li>{arrayChildren[childrenCount - 1]}</li>
                {indexChildren.map((child, i) => {
                    return (
                        <li
                            key={arrayChildren.indexOf(arrayChildren[child])}
                            className={i == current - 1 ? style?.current : ''}
                        >
                            {child !== undefined ? arrayChildren[child] : null}
                        </li>
                    );
                })}
                <li>{arrayChildren[0]}</li>
            </ul>
            <a
                onClick={goToPrevious}
                className={style?.prev}
                href="#"
                aria-label="Previous"
            >
                {arrow === undefined ? <SVGArrow /> : arrow}
            </a>
            <a
                onClick={goToNext}
                className={style?.next}
                href="#"
                aria-label="Next"
            >
                {arrow === undefined ? <SVGArrow /> : arrow}
            </a>
        </div>
    );
}
