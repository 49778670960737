import React from 'react';

import CustomLink from '@components/global/CustomLink';

import styles from './More.module.css';

interface MoreProps {
    size?: 'big' | 'small';
    href?: string;
    customLink?: JSX.Element;
    color?: 'blue' | 'yellow' | 'orange' | 'white' | 'feature-blue';
    label: string;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
}

export const More = ({
    size = 'small',
    href,
    customLink,
    color = 'blue',
    label,
    className,
    onClick,
    ...props
}: MoreProps): React.ReactElement => {
    return (
        <>
            {href ? (
                <a
                    {...props}
                    href={href}
                    onClick={onClick}
                    className={[
                        styles.more,
                        className,
                        `${color ? styles[color] : ''}`,
                        `${size ? styles[size] : ''}`,
                    ].join(' ')}
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                />
            ) : customLink ? (
                <CustomLink
                    {...customLink.props}
                    {...props}
                    className={[
                        styles.more,
                        className,
                        `${color ? styles[color] : ''}`,
                        `${size ? styles[size] : ''}`,
                    ].join(' ')}
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                />
            ) : (
                <span
                    {...props}
                    onClick={onClick}
                    className={[
                        styles.more,
                        className,
                        `${color ? styles[color] : ''}`,
                        `${size ? styles[size] : ''}`,
                    ].join(' ')}
                    dangerouslySetInnerHTML={{
                        __html: label,
                    }}
                />
            )}
        </>
    );
};
