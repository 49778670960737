import React, { FunctionComponent, cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Article, { List, ListItem, Paragraph } from '@components/global/Article';
import CustomLink from '@components/global/CustomLink';
import FeatureItem from '@components/global/FeatureItem';
import Split, { Column1, Column2 } from '@components/global/Split';
import FeatureTooltip from '@components/ui/FeatureTooltip';
import Label from '@components/ui/Label';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteFeaturesEmailMarketingEmailCreator } from '@static_components/laravelLinks';
import { multisiteRouteHelpPostHowGoodIsYourEmailDeliverability } from '@static_components/multisiteLinks';

import styles from './MarketingBeyond.module.css';
import { ReactComponent as SVGIcon1 } from './assets/ico-1.svg';
import { ReactComponent as SVGIcon2 } from './assets/ico-2.svg';
import { ReactComponent as SVGIcon3 } from './assets/ico-3.svg';
import { ReactComponent as SVGIcon4 } from './assets/ico-4.svg';

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();

    return (
        <Split className={styles.gap}>
            <Column1>
                <Article
                    title={
                        <Trans
                            i18nKey="marketingTitle"
                            components={{
                                mark: <Mark />,
                                br: <br />,
                            }}
                            ns="pages/features/email-marketing/ai-email-generator/index"
                        />
                    }
                    articleContent={
                        <>
                            <Trans
                                i18nKey="marketingText"
                                components={{
                                    strong: <strong />,
                                    br: <br />,
                                    p: <Paragraph />,
                                    ul: <List />,
                                    li: <ListItem />,
                                    tooltip: (
                                        <FeatureTooltip ariaRole="dialog">
                                            <Trans
                                                i18nKey="marketingTooltip"
                                                components={{
                                                    URL: (
                                                        <a
                                                            href={multisiteRouteHelpPostHowGoodIsYourEmailDeliverability(
                                                                locale,
                                                            )}
                                                        />
                                                    ),
                                                }}
                                                ns="pages/features/email-marketing/ai-email-generator/index"
                                            />
                                        </FeatureTooltip>
                                    ),
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        </>
                    }
                />
            </Column1>
            <Column2 type="other">
                <ul className={styles.list}>
                    <FeatureItem
                        icon={
                            <SVGIcon1
                                width={52}
                                height={52}
                            />
                        }
                        href={laravelRouteFeaturesEmailMarketingEmailCreator(locale)}
                        title={
                            <Trans
                                i18nKey="marketingTitle1"
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        text={
                            <Trans
                                i18nKey="marketingText1"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                    />
                    <FeatureItem
                        icon={
                            <SVGIcon2
                                width={52}
                                height={52}
                            />
                        }
                        customLink={<CustomLink href={{ pathname: 'features/email-marketing/templates' }} />}
                        title={
                            <Trans
                                i18nKey="marketingTitle2"
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        text={
                            <Trans
                                i18nKey="marketingText2"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                    />
                    <FeatureItem
                        icon={
                            <SVGIcon3
                                width={52}
                                height={52}
                            />
                        }
                        title={
                            <Trans
                                i18nKey="marketingTitle3"
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        text={
                            <Trans
                                i18nKey="marketingText3"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                        customLink={
                            locale && !['ru', 'es', 'pt', 'fr'].includes(locale) ? (
                                <CustomLink href={{ pathname: 'features/email-marketing/email-assistant' }} />
                            ) : undefined
                        }
                    />
                    <FeatureItem
                        icon={
                            <SVGIcon4
                                width={52}
                                height={52}
                            />
                        }
                        title={
                            <>
                                <Label
                                    className={styles.label}
                                    text={t('marketingLabel')}
                                />
                                <Trans
                                    i18nKey="marketingTitle4"
                                    ns="pages/features/email-marketing/ai-email-generator/index"
                                />
                            </>
                        }
                        text={
                            <Trans
                                i18nKey="marketingText4"
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        }
                    />
                </ul>
            </Column2>
        </Split>
    );
};

interface MarketingBeyondProps {
    container: React.ReactElement;
}

export default function MarketingBeyond({ container }: MarketingBeyondProps): JSX.Element {
    return (
        <section className={styles.beyond}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1140px',
            })}
        </section>
    );
}
