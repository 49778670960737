import React from 'react';

import CustomLink from '@components/global/CustomLink';
import { More } from '@components/ui/More';

import clsx from 'clsx';

import styles from './ResourceCard.module.css';

interface ResourceCardProps {
    picture: JSX.Element;
    title: string;
    text: string;
    more: string;
    href?: string;
    linkProps?: {
        target?: '_self' | '_blank';
    };
    customLink?: JSX.Element | null;
    linkAlignedToBottom?: boolean;
    border?: boolean;
    className?: string;
}

function ResourceCard({
    picture,
    title,
    text,
    more,
    href,
    customLink,
    linkAlignedToBottom = true,
    border = false,
    className,
    linkProps,
}: ResourceCardProps): JSX.Element {
    return (
        <article className={clsx(styles.card, border ? styles.border : '', className)}>
            <figure className={styles.image}>{picture}</figure>
            <div className={clsx(styles.desc, linkAlignedToBottom ? styles.bottomlink : '')}>
                <div>
                    <h3>
                        {href ? (
                            <a
                                href={href}
                                target={linkProps?.target}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        ) : customLink ? (
                            <CustomLink
                                {...customLink.props}
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />
                        ) : (
                            <span dangerouslySetInnerHTML={{ __html: title }} />
                        )}
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                {(href || customLink) && (
                    <More
                        className={styles.more}
                        color="blue"
                        size="small"
                        label={more}
                    />
                )}
            </div>
        </article>
    );
}

export default ResourceCard;
