/**
 * FIXME - lots of unused vars in this file. We should double check if they are really redundant,
 * or maybe they should be passed on as props?
 */

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import { addQueryParamsToSerachParams } from '@helpers/utils';

import { laravelRouteStartFree } from '@static_components/laravelLinks';
import clsx from 'clsx';

import styles from './Button.module.css';

interface CTAButtonStartFreeCreateAccountProps {
    variant?: 'text' | 'contained' | 'outlined' | 'solid' | 'solidInverse';
    color?: 'blue' | 'yellow' | 'orange' | 'green' | 'navy' | 'black';
    label: string | JSX.Element;
    className?: string;
    size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
    href?: string;
    target?: string;
    customLink?: JSX.Element;
    type?: 'button' | 'submit';
    disabled?: boolean;
    rel?: string;
    onClick?: (e: React.MouseEvent) => void;
    params?: object | null;
}

export const CTAStartFreeCreateAccount = ({
    variant,
    href,
    target,
    customLink,
    color = 'blue',
    label,
    size = 'large',
    className,
    type = 'button',
    disabled = false,
    rel = '',
    params = null,
    ...otherProps
}: CTAButtonStartFreeCreateAccountProps) => {
    const { locale } = useRouter();
    const { pageProperties } = useAppContext();
    let prepareLinkToStartFree;

    if (params !== null) {
        const isUndefinedPathInfo = Object.keys(params).find((key) => key === 'pathinfo');
        if (isUndefinedPathInfo === undefined) {
            const newParams = {
                ...pageProperties,
                ...params,
            };
            prepareLinkToStartFree = addQueryParamsToSerachParams(newParams, laravelRouteStartFree(locale));
        } else {
            prepareLinkToStartFree = addQueryParamsToSerachParams(params, laravelRouteStartFree(locale));
        }
    } else {
        prepareLinkToStartFree = addQueryParamsToSerachParams(pageProperties, laravelRouteStartFree(locale));
    }

    return (
        <a
            {...otherProps}
            href={prepareLinkToStartFree}
            className={clsx(
                styles.btn,
                className,
                `${variant ? styles[variant] : ''}`,
                `${color ? styles[color] : ''}`,
                size ? styles[size] : '',
            )}
        >
            <span>{label}</span>
        </a>
    );
};
