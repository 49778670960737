import React from 'react';

import CustomLink from '@components/global/CustomLink';

import clsx from 'clsx';

import styles from './FeatureItem.module.css';

interface FeatureItemProps {
    label?: string;
    icon: JSX.Element;
    title?: JSX.Element | string;
    text: JSX.Element | string;
    textColor?: 'white' | 'grey';
    href?: string;
    customLink?: JSX.Element;
    className?: string;
}

export default function FeatureItem({
    icon,
    title,
    label,
    text,
    textColor,
    href,
    customLink,
    className,
}: FeatureItemProps): JSX.Element {
    return (
        <li className={clsx(styles['feature-item'], className)}>
            <span className={styles.icon}>{icon}</span>
            <div>
                {label ? label : null}
                <h3>
                    {href ? (
                        <a href={href}>{title}</a>
                    ) : customLink ? (
                        <CustomLink {...customLink.props}>{title}</CustomLink>
                    ) : (
                        <span>{title}</span>
                    )}
                </h3>
                <p className={clsx(textColor && styles[textColor])}>{text}</p>
            </div>
        </li>
    );
}
