import React, { cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Heading from '@components/global/Heading';
import { CTAStartFreeCreateAccount } from '@components/ui/CTAStartFreeCreateAccount';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';

import styles from './GenerateTemplate.module.css';

interface InnerContentProps {
    version?: string;
    pageinfo?: string;
}

const Content = ({ version, pageinfo }: InnerContentProps) => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();
    const videoSource = (locale: string = 'en'): JSX.Element => {
        switch (locale) {
            case 'de':
                return (
                    <iframe
                        loading="lazy"
                        src="https://www.youtube.com/embed/rA9B5zpzCcA"
                        title="YouTube video player"
                        className={styles.wistia}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        rel="0"
                    />
                );
            case 'it':
                return (
                    <iframe
                        loading="lazy"
                        src="https://www.youtube.com/embed/Hp-BALq7-aI"
                        title="YouTube video player"
                        className={styles.wistia}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        rel="0"
                    />
                );
            case 'pl':
                return (
                    <iframe
                        loading="lazy"
                        src="https://fast.wistia.net/embed/iframe/9cj4x24224"
                        title="Kreator emaili AI"
                        allow="autoplay; fullscreen"
                        className={styles.wistia}
                        name="wistia_embed"
                    />
                );
            default:
                return (
                    <iframe
                        loading="lazy"
                        src="https://fast.wistia.net/embed/iframe/n0uzuq2d90"
                        title="AI email generator"
                        allow="autoplay; fullscreen"
                        className={styles.wistia}
                        name="wistia_embed"
                    />
                );
        }
    };

    return (
        <>
            <Heading>
                <Trans
                    i18nKey="videoTitle"
                    components={{
                        mark: <Mark />,
                    }}
                    ns="pages/features/email-marketing/ai-email-generator/index"
                />
            </Heading>
            <div className={styles['video-wrapper']}>
                <div className={styles.video}>{videoSource(locale)}</div>
            </div>
            <CTAStartFreeCreateAccount
                params={{ version, pageinfo }}
                label={t('videoBtn')}
                size="medium"
                color="yellow"
                className={styles.button}
            />
        </>
    );
};

interface GenerateTemplateProps extends InnerContentProps {
    container: React.ReactElement;
}

export default function GenerateTemplate({ container, version, pageinfo }: GenerateTemplateProps): JSX.Element {
    return (
        <section className={styles['generate-template']}>
            {cloneElement(container, {
                children: (
                    <Content
                        version={version}
                        pageinfo={pageinfo}
                    />
                ),
                maxWidth: '1180px',
            })}
        </section>
    );
}
