import React from 'react';

import FreeTrialForm from '@components/global/FreeTrialForm';

import clsx from 'clsx';

import styles from './FreeTrialTestimonial.module.css';

interface FreeTrialTestimonialProps {
    picture: JSX.Element;
    title: string;
    author: string;
    quote: string | JSX.Element;
    sftVersion?: string;
    button?: string;
    pageInfo?: string;
    quoteSize?: 'small' | 'medium' | 'big';
    text?: string;
    className?: string;
}

export function FreeTrialTestimonial({
    picture,
    sftVersion,
    pageInfo,
    button,
    title,
    quote,
    quoteSize = 'small',
    author,
    text,
    className,
}: FreeTrialTestimonialProps): JSX.Element {
    return (
        <div className={clsx(styles.container, className)}>
            <div className={styles.formwrapper}>
                <h2
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                />
                {text && (
                    <p
                        className={styles.text}
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                )}
                <FreeTrialForm
                    version={sftVersion}
                    pageinfo={pageInfo}
                    styles={styles}
                    button={button}
                />
            </div>
            <div className={styles.image}>
                {picture}
                <blockquote className={clsx(styles.blockquote, styles[quoteSize])}>
                    <p className={styles.cite}>{quote}</p>
                    <footer className={styles.author}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: author,
                            }}
                        />
                    </footer>
                </blockquote>
            </div>
        </div>
    );
}

export default FreeTrialTestimonial;
