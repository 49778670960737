import React from 'react';

import clsx from 'clsx';

import styles from './Split.module.css';

interface Column1Props {
    children?: Array<JSX.Element> | JSX.Element;
}

export function Column1({ children }: Column1Props): JSX.Element {
    return <article className={styles.desc}>{children}</article>;
}

interface Column2Props {
    type?: 'figure' | 'other';
    children?: Array<JSX.Element> | JSX.Element;
}

export function Column2({ children, type = 'figure' }: Column2Props): JSX.Element {
    return type == 'other' ? (
        <div className={styles.items}>{children}</div>
    ) : (
        <figure className={styles.picture}>{children}</figure>
    );
}

interface SplitProps {
    inviewRef?: React.RefObject<HTMLDivElement> | ((node?: Element | null) => void);
    reverse?: boolean;
    centerVertical?: boolean;
    className?: string;
    children: [JSX.Element, JSX.Element];
}

function Split({ inviewRef, reverse = false, centerVertical = false, className, children }: SplitProps): JSX.Element {
    return (
        <div
            ref={inviewRef}
            className={clsx(
                styles.split,
                reverse ? styles.reverse : '',
                centerVertical ? styles.center : '',
                className,
            )}
        >
            {children}
        </div>
    );
}

export default Split;
