import React from 'react';

import clsx from 'clsx';

import styles from './FeaturesList.module.css';

interface ContentProps {
    heading?: JSX.Element;
    firstImagePosition?: 'start' | 'end';
    flipPosition?: boolean;
    className?: string;
}

export default function FeaturesList({
    heading,
    firstImagePosition = 'start',
    flipPosition = true,
    children,
    className,
}: React.PropsWithChildren<ContentProps>) {
    return (
        <>
            {heading}
            <div
                className={clsx(
                    styles['feature-list'],
                    flipPosition && styles.flip,
                    firstImagePosition && styles[firstImagePosition],
                    className,
                )}
            >
                {children}
            </div>
        </>
    );
}
